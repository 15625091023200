
import { Component, Prop, Provide, Vue, Watch } from "vue-property-decorator";
import { ProductList, ProductMenu } from "@/interface/pagas";
import {gsap} from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
@Component({
  components: {},
  computed: {}
})
export default class Product extends Vue {
  @Provide() currentName: string = "";

  @Prop({ type: Array, default: [] }) readonly menuList?: Array<ProductMenu>;
  @Prop({ type: Number, default: 0 }) readonly menuListIndex?: number;

  @Prop({ type: Array, default: [] }) readonly productList?: Array<ProductList>;
  @Prop({ type: Number, default: 0 }) readonly productListTotal?: number;
  @Prop({ type: Number, default: 0 }) readonly totalf?: number;
  @Prop({ type: Boolean, default: false }) readonly totalFlgs?: boolean;
  @Prop({ type: Boolean, default: false }) readonly iLoading?: boolean;

  mounted() {
   
    const _this: any = this;
    
  }
}
